.custom-flexible-section{
    &.banner-cta-section{
        background:$newMainBlue;
        border-top:20px solid $white;
        border-bottom:20px solid $white;
        position:relative;
        padding:20px 0;
        background-size:cover;
        background-repeat:no-repeat;

        .wp-video{
          width: 50% !important;
          @media(max-width:992px){
            width: 100% !important;
          }

        }
        .custom-wrapper{
            .row-eq-height{
                align-items: center;
            }
        }

        &:before{
            background: none;
            border-top: 20px solid $newLightBlue;
            border-bottom: 20px solid $newLightBlue;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
        }

        &:after{
            content:"";
            width:50%;
            position:absolute;
            height:calc(100% - 40px);
            top:20px;
            right:0;
            bottom:20px;
            background: linear-gradient(to right, rgba(3, 34, 85, 0) 0%, rgba(1, 9, 51, 0.86) 100%);
            @media(max-width:992px){
                width:100%;
            }
        }



        .custom-wrapper, .col-lg-6, .row{
            height:100%;
        }

        .image-holder{
            height:100%;
            @media(max-width:992px){
                display:none;
            }
            img{
                max-width:100%;
                height:90%;
                max-height:600px;
                display:block;
                margin:0 auto;
            }
        }

        .details-wrapper{
            height:100%;

            color:$white;
            position:relative;
            z-index:10;
            display:flex;
            align-items: center;
            justify-content: center;

            .details{
                height:100%;
                margin:20px 0 20px auto;
                padding:100px 0;
                max-width:80%;

                @media(max-width:576px){
                    text-align: center;
                }

                @media(max-width:1200px){
                    max-width:100%;
                    margin:20px auto;
                }


                h2,h3{
                    margin:0;
                }

                h2{
                    text-transform: uppercase;
                    font-size:58px;
                    @media(max-width:576px){
                        font-size:48px;
                    }

                    &.large-title{
                        font-size: 6vw;
                        line-height: 1;
                        @media(max-width:992px){
                            font-size: 10vw;
                        }
                    }

                }

                h3{
                    font-weight:800;
                    font-size:48px;
                    @media(max-width:576px){
                        font-size:32px;
                    }
                }

                .cta{
                    margin-top:30px;
                    a{
                        text-transform:uppercase;
                        background:$newGold;
                        color:black;
                        font-weight:700;
                        padding:10px 30px;
                        text-align:center;
                        max-width:100%;
                        display:inline-block;
                        transition:0.3s;
                        &:hover{
                            text-decoration: none;
                            opacity:1;
                            background: #ffc658;
                        }
                    }
                }
            }
        }

        &.image-not-set{
            &:after {
                width: 100%;
            }
            .details{
                max-width:100%;
                margin:20px 0;
            }
        }

    }
}
