.custom-flexible-section{
    &.image-banner-section{
      .wp-video{
        width: 100% !important;
      }
      .cta{
          display: flex;
          justify-content: center;
          text-transform:uppercase;
          text-align:center;
          font-weight:700;
          margin:0 12.5px;
          padding-top: 12.5px;
          padding-bottom: 12.5px;
          a{
              color:black;
              background:$newGold;
              padding:10px 20px;
              display:block;
              transition:0.3s;
              &:hover{
                  text-decoration: none;
                  opacity:1;
                  background: #ffc658;
              }
          }
      }
        a{
            display:block;
        }
        img{
            width:100%;
            height:auto;
        }
    }
}
