.menu-item a, .team-custom-filter-bar ul li a {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
}

.menu-item a {
    text-decoration:none;
}

.menu-item a:before,
.menu-item a:after,
.team-custom-filter-bar ul li a:before,
.team-custom-filter-bar ul li a:after {
    content:'';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #3DBFEF;
    top: 100%;
    left: 0;
    pointer-events: none;
}

.menu-item a:before, .team-custom-filter-bar ul li a:before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

.menu-item a:hover:before, .team-custom-filter-bar ul li a:hover:before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
    transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
}

.menu-item a:after, .team-custom-filter-bar ul li a:after {
    content: '';
    top: calc(100% + 4px);
    transform-origin: 0% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

.menu-item a:hover:after, .team-custom-filter-bar ul li a:hover:after {
    transform-origin: 100% 50%;
    transform: scale3d(1, 1, 1);
    transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
}

.team-custom-filter-bar ul li a.active {
        border-bottom: 1px solid #3DBFEF;
}

.team-archive-item.person .image-wrapper a:before {
    content:'';
    background-image:url('/wp-content/themes/dundeefc/dist/images/new-assets/Dundee-Football-Club-hover.svg');
    background-size:contain;
    width:60%;
    left:0%;
    height:550px;
    display:block;
    background-repeat:no-repeat;
    position:absolute;
    top:0%;
    opacity:0;
    transition:1.5s all ease;

}

.team-archive-item.person .image-wrapper a:after {
    content:'';
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5) 39%, rgba(0,0,0,0) 70%);
    display:block;
        z-index:-1;
        opacity:0;
    transition:1.5s all ease;
}

.team-archive-item.person .image-wrapper a:hover:before{
    top:3%;
    opacity:1;
}

.team-archive-item.person .image-wrapper a:hover:after {
    opacity:1;
}

.team-archive-item.person .image-wrapper a img {
    transition:0.5s all ease;
    filter: brightness(1);
    transform:translate(0,0);
}

.team-archive-item.person .image-wrapper a:hover img {
    filter: brightness(1.1);
    transform:translate(0,-5px);
}

.custom-flexible-section.hero-section .info-wrapper .secondary-wrapper .image-wrapper img {
    object-fit:unset;
    height:auto;
}

.hero-section:before, .post-type-archive-match:before, .page-template-template-taxonomy_team:before {
    content:'';
    background-image:url('https://d1ssu070pg2v9i.cloudfront.net/2022/02/10170049/mobile-100-bg.png');
    background-image:url('https://dundeefc.co.uk/wp-content/uploads/2022/02/mobile-100-bg.png');
  position: fixed;
    left:0;
    right:0;
    bottom:0;
  top: 0;
  z-index: -1;
  max-width: 100%;
  width: 100%;
    background-size:contain;
    background-repeat:no-repeat;
    opacity:0.6;
}

.hero-section .banner-100, .post-type-archive-match .top-banner-100, .page-template-template-taxonomy_team .top-banner-100 {
    display:none;
}

.top-banner-100 {
    top:0;
}
.custom-flexible-section.match-section .flexible-league-table table tr.highlighted td {
    background: #3DBFEF;
}
.single-post .single-post-holder .standard-post-output .entry-content {
    background: rgba(255,255,255,0.8);
}

.additional-content-section {
    background-position:bottom;
}

.custom-flexible-section.hero-section .info-wrapper .secondary-wrapper .image-wrapper {
    padding-top: calc(750 / 1200 * 100%);
}

.standard-post .image-wrapper {
    padding-bottom: 65%;
}

.custom-flexible-section.featured-news-section .article-wrapper .image-wrapper img.overlay {
    object-fit: contain;
    height: auto;
}

.custom-flexible-section.match-section {
    -webkit-background-size: contain;
    background-size: contain;
    background-position: bottom left;
}

.archive.category .top-section, .blog .top-section {
    background-size:contain;
    background-position:left;
}

.custom-flexible-sections.community-section .additional-content-grid .image-wrapper img {
    object-fit: cover;
}


.home .next-matches-wrapper {
    display:none;
}
.home .matches-wrapper {
    position:relative;
}
.home .hero-section .info-wrapper:after {
    content:'';

    bottom:0;

    background-image:url('https://extranet.avian.co.uk/dfc/DFC-Going-Up-loop-lc.svg');
    height: 55vw;
    width: 55vw;
    margin: 0 auto;
    margin-top: -40px;
    background-size:contain;
    background-repeat:no-repeat;
}

.custom-team-single .top-section .primary-section video {
    filter: brightness(1.2);
}

.adam-legzdins .custom-team-single .top-section .primary-section video, .jon-mccracken .custom-team-single .top-section .primary-section video, .harrison-sharp .custom-team-single .top-section .primary-section video, .trevor-carson .custom-team-single .top-section .primary-section video{
    filter: brightness(1.1);
}


.custom-team-single .bio-section {
        padding-top:0px;
    }

.custom-team-single .top-section .primary-section {
    align-items: end;
}

.custom-team-single .bio-section .image-col {
    align-items: start;
}



@media (min-width: 800px){
    .hero-section:before, .post-type-archive-match:before, .page-template-template-taxonomy_team:before {
        background-image:none;
    }
    .hero-section .banner-100, .post-type-archive-match .banner-100, .page-template-template-taxonomy_team .top-banner-100 {
        display:unset;
    }
}
@media (min-width: 992px) {
    .custom-flexible-section.hero-section .info-wrapper .primary-wrapper .matches-wrapper .match-wrapper .versus {
        font-size: 0.9vw;
    }
    .home .hero-section .info-wrapper:after  {
        position:absolute;
        margin-left:-1.4vw;
    height:22vw;
    width:30vw;
        bottom:70px;
    }
    .custom-team-single .top-section .primary-section video {
    max-width:110%;
    }

}
@media (min-width: 1024px) {
    .custom-sponsors .secondary-sponsors .secondary-sponsor {
        padding:10px;
    }
    .archive.category .top-section, .blog .top-section {
        padding-bottom:120px;
    }
}

@media (min-width: 1400px) {
    .custom-flexible-section.hero-section .info-wrapper .secondary-wrapper .image-wrapper img {
        object-fit:cover;
        height:100%;
    }

    .custom-flexible-section.hero-section .info-wrapper .primary-wrapper .matches-wrapper .match-wrapper .versus {
        font-size: 0.8vw;
    }

    .custom-team-single .top-section .primary-section video {
    max-width:114%;
    }
}
@media (min-width: 1601px) {
    .home .hero-section .info-wrapper:after {
        margin-left:-1.4vw;
    height:30vw;
    width:30vw;
    bottom:0;
    }
}
/* Hide Animation over info box on home banner */
.home .hero-section .info-wrapper:after{
    display:none;
}

.home .last-match::before {
    content:none;
}
/*
.term-management-staff .custom-wrapper .row{
    display: flex;
    flex-wrap: wrap;
} */

.ccc-content--dark{
    background-color: #000223 !important;
}
