.custom-woocommerce-pages{
    .wrapper{
        @media(min-width: 992px) {
            max-width: 80%;
        }
        @media(min-width: 1200px) {
            max-width: 60%;
        }
        margin:0 auto;
        padding:0 25px 50px 25px;
    }
    .checkout-button, button[type="submit"]{
        background: $newGold !important;
        color: $newMainBlue !important;
        text-transform: uppercase;
        transition:background 0.3s;
        &:hover{
            background:#ffc658 !important;
        }
    }

    .select2-container .select2-selection--single{
        height:auto;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered{
        padding:1rem;
    }

    input[type="text"], input[type="tel"],input[type="email"], textarea{
        padding:1rem;
    }

    @media(max-width:991px){
        .col2-set{
            display:flex;
            flex-direction: column;
            .col-1{
                width: 100% !important;
            }
        }
    }

}
