.custom-flexible-sections {
    &.honours-section {
        background-size:cover;
        background-repeat:no-repeat;
        
        .custom-wrapper{
            padding-left: 0;
            padding-right: 0;
            
            .honours-wrapper{
                background: rgba($brand-primary,.85);
                color: #fff;

                .top-section{
                    text-align:center;
                    color:$newLightBlue;
                    padding-top:48px;
                    .primary-title{
                        text-transform:uppercase;
                        margin-top:0;
                        font-family:"Zooja", sans-serif;
                        font-size:60px;
                        margin-bottom: -20px;
                        @media(min-width:576px){
                            font-size:80px;
                            margin-bottom: -30px;
                        }
                        @media(min-width:992px){
                            font-size:85px;
                            margin-bottom: -40px;
                        }
                    }

                    .secondary-title {
                        color: #fff;
                        margin-top:0;
                        margin-bottom:0;
                        font-size:35px;
                        text-transform:uppercase;
                        font-weight:800;
                        @media(min-width:576px){
                            font-size:48px;
                        }
                        @media(min-width:992px){
                            font-size: 50px;
                        }
                    }
                }

                .honours-row{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    .honour-item{
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        position:relative;
                        text-align: center;
                        padding: 15px 30px;
                        @media (min-width:991px){
                            width: 16.66%;
                        }
                        .image-wrapper{
                            position: relative;
                            margin-bottom: 10px;
                            max-width: 100%;
                        }
                        p{
                            color: #fff;
                            margin-bottom: 0;
                            line-height: 1.1em;
                            ~p{
                                color: $newLightBlue;
                            }
                        }
                        a{
                            display:block;
                            transition:0.3s;
                            &:hover{
                                filter:brightness(105%);
                                .cta-text{
                                    opacity:1;
                                    background: #ffc658;
                                    transform: translate(-50%, calc(50% - 0.5rem));
                                }

                            }
                        }
                    }
                    .honour-image{
                        width: 150px;
                        height: 100%;//220px;
                        max-width: 100%;
                        object-fit: contain;
                        object-position: center bottom;
                        position: relative;
                        z-index: 2;
                        @media (min-width:991px){
                            width: 175px;
                        }
                        @media (min-width:1199px){
                            width: 210px;
                        }
                        &--shadow{
                            margin-left: -25%;
                            opacity: .5;
                            position: absolute;
                            transform: scale(.8);
                            z-index: 0;
                        }
                    }
                    .honour-details{
                        flex-grow: 1;
                        text-transform: uppercase;
                        margin-left: -20px;
                        margin-right: -20px;
                    }

                }
                .cta{
                    padding: 2rem;
                    text-align: center;
                    a{
                        background: $newGold;
                        text-transform: uppercase;
                        color: #000;
                        font-weight: 700;
                        margin: 0 auto -25px;
                        padding: 10px 30px;
                        text-align: center;
                        max-width: 100%;
                        display: inline-block;
                        transition: .3s;
                    }
                }
            }
        }
    }
}
