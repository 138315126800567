footer{
    background:$newMainBlue;
    color:$white;
    .custom-wrapper{
        padding-top:32px;
        padding-bottom:32px;


        .menu-wrapper{
            display:flex;
            justify-content: center;
            ul{
                margin-bottom:0;
                padding-left:0;
                text-align:center;
                li{
                    margin-right:2rem;
                    display:inline-block;
                    text-transform:uppercase;
                    font-weight:700;
                    .my-dropdown-toggle{
                        display:none;
                    }
                    a{
                        color:$white;
                    }
                    .sub-menu{
                        display:none;
                    }
                }
            }
        }

        .social-wrapper{
            display:flex;
            align-items: center;
            justify-content: center;
            padding:32px 0;
            ul{
                padding-left:0;
                margin-bottom:0;
                li{
                    list-style-type:none;
                    font-size:32px;
                    display:inline-block;
                    margin:0 16px;
                    a{
                        color:$newLightBlue;
                        transition:0.3s;
                        text-decoration: none;
                        &:hover{
                            color:$newGold;
                        }
                    }
                }
            }
        }
    }

    .bottom-row{

        font-size:16px;

        .container-fluid{
            padding:0 !important;
        }

        .row{
            display:flex;
            align-items: flex-end;
            @media (max-width:$screen-md-min){
                flex-direction: column;
            }
        }

        ul{
            padding-left:0;
            margin-bottom:0;
            li{
                list-style-type: none;
                a{
                    color:$white;
                }
            }
        }

        .info-col{
            .custom-wrap{
                display:flex;
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
                flex-direction: column;
                @media(min-width:576px) {
                    flex-direction: row;
                    text-align: left;
                    justify-content: space-between;
                }
            }
        }

        .doc-col{
            @media (max-width:$screen-md-min){
                margin:2rem 0;
            }
            ul{
                text-align: center;
                li{
                    display:inline-block;
                    margin-right:2rem;
                    &:last-child{
                        margin-right:0;
                    }
                }
            }
        }

        .website-by-col{
            .custom-wrap{
                display:flex;
                justify-content: flex-end;
                @media (max-width:$screen-md-min){
                    justify-content: center;
                }
                a{
                    color:$white;
                    display:flex;
                    align-items: flex-end;
                    p{
                        margin-bottom:0;
                        margin-right:1rem;
                    }
                }
            }

        }
    }
}

.custom-newsletter{

    position:relative;
    background:$newMidBlue;
    color:$white;

    img.newsletter-bg{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit: cover;
    }

    .custom-wrapper {
        padding-top:48px;
        padding-bottom:48px;
    }

    .submit-row{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        img{
            width:300px;
            margin: 0 auto;
        }
    }
    .box-wrapper{
        background:rgba(#081c40, 0.8);
        padding:48px 5%;

        @media(min-width:1024px) {
            padding: 48px 10%;
        }
        @media(min-width:1400px) {
            padding: 48px 20%;
        }
        position:relative;
        z-index:10;
        h2{
            text-transform:uppercase;
            text-align:center;
            font-weight:800;
            font-size: 40px;
            @media(min-width:576px) {
                font-size: 50px;
            }
            @media(min-width:1024px) {
                font-size: 70px;
            }
            span{
                color:$newLightBlue;
            }
        }
        .description{
            text-align:center;
            span{
                color:$newGold;
            }
        }

        form{
            .input-grid{
                display:grid;
                grid-gap:2rem;
                @media(min-width:576px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                margin:32px 0;

                input[type="email"], input[type="text"]{
                    border-radius:0;
                    background:transparent;
                    border:none;
                    border-bottom:1px solid rgba($white, 0.6);
                    padding:1rem 0;
                    font-size:24px;
                    &::placeholder{
                        color:$white;
                        opacity:0.6;
                    }
                }
            }

            input[type="submit"]{
                background:$newGold;
                text-transform:uppercase;
                color:black;
                border:none;
                display:block;
                margin:2rem auto;
                padding:1rem 3rem;
                text-align: center;
                font-size:20px;
                font-weight:800;
                width:300px;
                max-width:100%;
                &:hover{
                    opacity:1;
                    background: #ffc658;
                }
            }
        }

        .disclaimer{
            max-width: 90%;
            @media(min-width:576px) {
                max-width: 60%;
            }
            font-size:14px;
            margin:32px auto 0 auto;
            text-align:center;
            p{
                margin-bottom:0;
            }
            a{
                color:#FFF;
            }
        }
    }
}

.custom-sponsors{
    background:$white;
    position:relative;

    .sponsor-wrapper{
        padding:32px 5%;
        @media(min-width:1024px) {
            padding: 32px 10%;
        }
        @media(min-width:1400px) {
            padding: 32px 20%;
        }
    }

    img{
        margin-bottom:2rem;
    }

    .main-sponsor{
        display: flex;
        justify-content: center;
    }

    .secondary-sponsors{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        .secondary-sponsor{
            width: 50%;
            @media(min-width:576px) {
                width: 33%;
            }
            @media(min-width:1024px) {
                width: 25%;
            }
            @media(min-width:1400px) {
                width: 20%;
            }
        }
    }

    h2{
        text-align:center;
        font-size:24px;
        text-transform:uppercase;
        font-weight:800;
        margin-bottom:2rem;
    }
}
