#kit-spons{
    margin:25px 0 75px 0;
    .kit-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        position:relative;


        .kit-spons-block{
            background: $white;
            padding:2rem 1rem;
            @media(min-width:1200px) {
                padding: 2rem 2rem 2rem 2rem;
            }
            text-transform: uppercase;
            margin-bottom:45px;
            text-align: center;
            color: $newMainBlue;

            .inner-kit{
                &.spons-active{
                    margin:7.5px 0;
                }
            }

            .name{
                font-weight:700;
            }

            .price{
                font-weight:700;
                margin-bottom: 15px;
            }

            .purchase-kit{
                background: $newGold;
                font-weight:700;
                padding:1rem;
                margin:-2rem 0 -2rem 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align:center;
                transform:translateY(50%);
                font-size:16px;
                span{
                    margin-right:10px;
                }
            }
        }

        .custom-col{
            text-align: center;
            margin-bottom:2rem;
            .kit-spons-block{
                max-width:50%;
                margin:0 auto;
            }
            h4{
                font-family: Zooja,sans-serif;
                color: #3DBFEF;
                text-transform: uppercase;
                font-size: 35px;
                text-align: center;
            }
        }


        @media (min-width: 850px) {
            padding-left: 35px;
            padding-right: 35px;
        }
        @media (min-width:992px) {
            padding-left: 60px;
            padding-right: 60px;
        }
        @media (min-width: 1300px) {
            padding-left: 120px;
            padding-right: 120px;
        }

        h2{
            background: #E7E7E7;
            text-transform:uppercase;
            color: $newMainBlue;
            padding:2rem;
            text-align:center;
            font-weight:700;
            margin:0;
            font-size: 25px;
            position:relative;
        }
        .output-wrapper{
            padding:3rem;
            background: $newMainBlue;
            display:flex;
            justify-content: space-between;
            flex-direction: column;
            position:relative;

            &:after{
                content:"";
                position:absolute;
                bottom:0;
                left:0;
                width:100%;
                height:40%;
                background-image: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(8,28,64,1) 38%);
                z-index:1;
            }

            .kit-bg{
                position:absolute;
                top:0;
                left:0;
                width:100%;
                z-index:1;
                height:100%;
                object-fit: cover;
            }

            @media(min-width:993px){
                flex-direction: row;
            }

            h3{
                margin:0;
                color: $white;
                text-transform: uppercase;
            }

            .kit-buy-output{
                width:100%;
                position:relative;
                z-index:2;
                @media(min-width:993px) {
                    width: 550px;
                }
                @media(min-width:1200px) {
                    width: 600px;
                }
                @media(min-width:1400px) {
                    width: 700px;
                }
                .items-output{
                    display: grid;
                    @media(min-width:993px) {
                        grid-template-columns: repeat(2, 1fr);
                    }
                    grid-gap:20px;
                    @media(min-width:1400px) {
                        grid-gap: 50px;
                    }
                    margin-top:40px;
                    h4{
                        font-family: Zooja,sans-serif;
                        color:$newLightBlue;
                        text-transform: uppercase;
                        font-size:35px;
                        text-align: center;
                    }
                }
            }

            .kit-carousel-output{
                width:100%;
                margin-top:50px;
                position:relative;
                z-index:2;
                @media(min-width:993px) {
                    margin-bottom:0;
                    width: calc(100% - 575px);
                }
                @media(min-width:1200px) {
                    width: calc(100% - 625px);
                }
                overflow: hidden;
                @media(min-width:1400px) {
                    width: calc(100% - 725px);
                }

                .kit-item{

                    .image-holder{
                        max-width: 60%;
                        @media(min-width:992px) {
                            max-width: 80%;
                        }
                        @media(min-width:1200px) {
                            max-width: 60%;
                        }
                        margin:0 auto 2rem auto;
                        position:relative;

                        .additional-images{
                            display:grid;
                            gap:1rem;
                            grid-template-columns: repeat(3, 1fr);
                            margin-top:1rem;

                            @media(min-width:1201px) {
                                margin-top:0;
                                top:0;
                                right:calc(-20% - 10px);
                                grid-template-columns: auto;
                                position: absolute;
                                width: 20%;
                            }

                            .small-image-wrapper{
                                height:0;
                                padding-bottom:100%;
                                position:relative;
                                img{
                                    position:absolute;
                                    height:100%;
                                    width:100%;
                                    object-fit: cover;
                                }
                            }
                        }

                        .image-wrapper{
                            height:0;
                            padding-bottom:100%;
                            position:relative;

                            &.default-image{
                                background-color: $newDarkBlue;
                                svg, img{
                                    max-width:60%;
                                    max-height:80%;
                                    position:absolute;
                                    top:50%;
                                    left:50%;
                                    transform:translate(-50%, -50%);
                                }
                            }

                            &.featured-image {
                                img {
                                    height: auto;
                                    max-width: 100%;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }


                        }
                    }

                    h3{
                        margin-bottom:1rem;
                        font-weight:700;
                        font-size:20px;
                        text-align: center;
                    }

                    .purchase-kit{
                        background: $newGold;
                        font-weight:700;
                        padding:1rem;
                        margin:2rem auto 0 auto;
                        width:max-content;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align:center;
                        text-transform: uppercase;
                        span{
                            margin-right:10px;
                        }
                    }

                    .content-wrapper{
                        @media(min-width:1201px) {
                            display: grid;
                        }
                        gap:1rem;

                        @media(max-width:1599px){
                            .text-wrapper { grid-area: 1 / 1 / 2 / 3; }
                            .kit-prev { grid-area: 2 / 1 / 3 / 2; }
                            .kit-next { grid-area: 2 / 2 / 3 / 3; }
                        }

                        @media(min-width:1600px) {
                            .text-wrapper { grid-area: 1 / 2 / 2 / 3; }
                            .kit-prev { grid-area: 1 / 1 / 2 / 2; }
                            .kit-next { grid-area: 1 / 3 / 2 / 4; }
                            grid-template-columns: 200px auto 200px;
                        }

                        color: $white;
                        text-align: center;

                        .text-wrapper{
                            .kit-description{
                                font-size:14px;
                                text-align: center;
                            }
                        }

                        .custom-control{
                            text-transform: uppercase;
                            font-weight:700;
                            display:flex;

                            font-size:12px;
                            width:max-content;
                            margin:2rem auto 0 auto;

                            @media(min-width:1201px) {
                                flex-direction: column;
                                width:auto;
                                margin:2rem 0 0 0;
                            }

                            @media(min-width:1400px) {
                                font-size: 16px;
                            }

                            @media(min-width:1600px) {
                                margin-top:0;
                                width: 200px;
                            }
                            flex-shrink: 0;
                            span{
                                color:$newGold;
                            }
                            &.kit-prev{
                                align-items: center;
                                @media(min-width:1201px) {
                                    align-items: flex-start;
                                }
                                span{
                                    margin-right:auto;
                                    @media(max-width: 1200px){
                                        margin-right:10px;
                                    }
                                }
                                .text{
                                    margin-right:auto;
                                    margin-left:0;
                                }
                            }
                            &.kit-next{
                                flex-direction: row-reverse;
                                align-items: center;
                                @media(min-width:1201px) {
                                    flex-direction: column;
                                    align-items: flex-end;
                                }
                                span{
                                    margin-left:auto;
                                    @media(max-width: 1200px){
                                        margin-left:10px;
                                    }
                                }
                                .text{
                                    margin-left:auto;
                                    margin-right:0;
                                }
                            }
                        }
                        .text{
                            margin:0 2rem;
                        }
                    }
                }
            }
        }
    }
}
