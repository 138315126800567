.custom-search-form{
  padding:15px 0;
  display:flex;
  justify-content: center;
  margin-top: -105px;
  transition:0.3s;

  &.active{
    margin-top:0;
  }
  .custom-wrapper{
    width:100%;
  }

  form{
    width:80%;
    margin:0 auto;

    @media(max-width:576px){
      width:100%;
    }

    input[type="search"]{
      padding:10px 20px;
      border:none;
      width:50%;
      @media(max-width:576px){
        width:auto;
        padding:10px;
      }
      box-shadow:none;
      &:focus, &:active, &:hover{
        outline:none;
      }
    }
    button{
      padding:10px 20px;
      @media(max-width:576px){
        padding:10px;
      }
      background:$newGold;
      color:black;
      text-transform:uppercase;
      font-weight:800;
      border:none;
      box-shadow:none;
    }
  }
}


header{
  background:$newDarkBlue;
  width:100%;
  border-top:8px solid $newLightBlue;

  .logo{
    @media (min-width:1100px) {
      margin-right: 16px;
    }
  }

  #main-primary-nav, #main-secondary-nav{
    @media(max-width:992px){
      display:none;
    }
  }

  .header-wrapper{
    position: relative;
    display:flex;
    justify-content: space-between;
    width:100%;

    padding-left:10px;
    padding-right:10px;
    @media (min-width:$screen-sm-min){
      padding-left:35px;
      padding-right:35px;
    }
    @media (min-width:1250px){
      padding-left:120px;
      padding-right:120px;
    }


    ul{
      padding-left:0;
      margin-bottom:0;
      li{
        display:inline-block;
        list-style-type:none;
        a{
          color:$white;
        }
      }
    }

    .primary-nav-wrapper{
      display:flex;
      text-transform:uppercase;
      align-items: center;
      .header-svg{
        height:81px;
        margin-right:32px;
        padding-top:1rem;
      }
      li{
        font-size:18px;
        margin-right: 10px;
        @media (min-width:1100px) {
          font-size:20px;
          margin-right: 16px;
        }
        @media(min-width:1400px) {
          font-size: 24px;
          margin-right:24px;
        }
        font-weight:700;
        &:last-child{
          margin-right:0;
        }
      }
    }

    .secondary-nav-wrapper{
      display:flex;
      text-transform:uppercase;
      align-items: center;
      ul{
        margin-right:32px;
      }
      li{
        font-size: 14px;
        margin-right: 10px;
        @media (min-width:1100px) {
          font-size: 16px;
          margin-right: 16px;
        }
        @media(min-width:1400px) {
          font-size:18px;
          margin-right: 32px;
        }
        font-weight:700;
      }

      .toggles{
        display:flex;
        height:100%;
        .menu-toggle{
          height:100%;
          width:87.28px;
          color:$white;
          display:flex;
          align-items: center;
          justify-content: center;
          font-size:24px;
          transition:0.3s;
          &.search-toggle{
            background:$newMidBlue;
            cursor:pointer;
            &:hover{
              opacity:0.8;
            }
          }
          &.hamburger-menu-toggle{
            border-right:2px solid $newMidBlue;
            cursor:pointer;
            .fa-bars{
              transition:0.3s;
            }
            &:hover{
              .fa-bars{
                opacity:0.8;
              }
            }
          }

          &.cart-toggle{
            border-right:2px solid $newMidBlue;
            a{
              position:relative;
            }
            .cart-product-count{
              background: $newLightBlue;
              color:$white;
              border-radius:50%;
              font-size:8px;
              display:flex;
              width:15px;
              height:15px;
              align-items: center;
              justify-content: center;
              font-weight:700;
              position:absolute;
              top:0;
              right:-10px;
            }
            .fa-shopping-bag{
              color:$white;
              transition:0.3s;
            }
            &:hover{
              .fa-shopping-bag{
                opacity:0.8;
              }
            }
          }

          &.translate-toggle{
            position:relative;

            .gt_float_switcher {
              background:transparent;
            }

            .gt_options{
              &.gt-open{
                background: #FFFFFF;
              }
              a{
                img{
                  display:none;
                }
              }
            }

            #gt_float_wrapper{
              position: absolute;
              top: 22px;
              left: 11px;
              z-index: 1000;
            }

            .gt-current-lang{
              display:flex;
              padding: 10px 0px;
              background:$newDarkBlue;
              color: #FFFFFF;
              img{
                display:none;
              }
              .gt-lang-code{
                font-size: 16px;
                margin-right:5px;
              }
            }
          }
        }
      }
    }
  }

  &.translate-active {
    .primary-nav-wrapper{
      .header-svg{
        margin-right:25px;
      }
      li{
        font-size:18px;
        margin-right: 10px;
        @media (min-width:1100px) {
          font-size:18px;
          margin-right: 14px;
        }
        @media(min-width:1400px) {
          font-size: 22px;
          margin-right:20px;
        }
      }
    }
    .secondary-nav-wrapper {
      ul {
        margin-right: 0;
      }

      li {
        font-size: 14px;
        margin-right: 8px;
        @media (min-width: 1100px) {
          font-size: 16px;
          margin-right: 14px;
        }
        @media(min-width: 1400px) {
          font-size: 18px;
          margin-right: 25px;
        }
      }

      .toggles {
        .menu-toggle {
          width:50px;
          @media (min-width: 375px) {
            width: 60px;
          }
        }
      }

    }
  }

}

.custom-title-wrapper{
  padding: 25px 10px;
  @media (min-width:$screen-sm-min){
    padding: 25px 35px;
  }
  @media (min-width:$screen-lg-min){
    padding: 25px 120px;
  }

  color:$white;
  background:transparent;
  position:relative;
  z-index:10;

  h1, span{
    margin:0;
    font-weight:700;
    text-transform:uppercase;
    font-size: 26px;
    @media (min-width: 450px){
      font-size:42px;
    }
  }
}

.hamburger-menu{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  max-height:100vh;
  background:#000;
  z-index:10000;
  background:$newDarkBlue;
  border-top:8px solid $newLightBlue;
  display:none;
  overflow: auto;
  justify-content: space-between;
  flex-direction: column;

  &.active{
    display:flex;
  }

  .close-menu{
    height:86px;
    color:white;
    display:flex;
    align-items: center;
    justify-content: space-between;
    font-size:24px;

    .custom-wrapper{
      width:100%;
      display:flex;
      justify-content: space-between;
      align-items: center;
      .logo{
        display:flex;
        align-items: center;
        svg{
          padding-top:1rem;
          height: 81px;
          width:auto;
        }
      }
    }

    .inner{
      cursor:pointer;
      width: 87.28px;
      display:flex;
      justify-content: center;
      .fa-times{
        transition:0.3s;
      }
      &:hover{
        .fa-times{
          opacity:0.8;
        }
      }
    }
  }

  .menu-wrapper{
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding:50px 0;
  }

  ul{
    padding-left:0;
    margin-bottom:25px;

    &.social{
      text-align:center;
      li{
        display:inline-block;
        margin:0 15px;
        a{
            font-weight: normal;
            text-decoration: none;
        }
      }
    }

    li{
      list-style-type:none;
      text-transform:uppercase;
      font-weight:800;
      text-align:center;
      font-size:24px;
      margin-bottom:10px;
      a{
        color:$white;
      }
    }
  }
}
