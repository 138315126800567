@font-face {
    font-family: "Zooja";
    src: url('../fonts/font.woff2') format('woff2'), url('../fonts/font.woff') format('woff');
    font-weight:400;
}


body:not(#tinymce){
    font-family: 'Lato',sans-serif;
    font-size: 17px;
    background-image: url('../images/new-assets/textured-bg.jpg');
    background-attachment: fixed;
    background-size:cover;
}

body.admin-bar {
    .content { margin-top:-30px;  }
}

.img-responsive{
    width: auto;
}

.wrap{
    position: relative;
    @media (min-width:$screen-sm-min){
        margin-left: 25px;
        margin-right: 25px;
    }
    @media (min-width: 1025px){
        margin-top:0;
    }
    @media (min-width:$screen-lg-min){
        margin-left: 110px;
        margin-right: 110px;
    }
}
.wrap.footer, .wrap.partners {
        margin-top:0px;
 }
.entry-content{
    p{
        margin-bottom: 30px;
    }
}

.margin-top{
    margin-top: 40px;
}


section{
    float: left;
    width: 100%;
    margin-bottom: $grid-gutter-width;
    position: relative;
}


.comment-form input[type="email"],
.comment-form input[type="text"],
.comment-form input[type="url"],
.comment-form textarea,
.sign-up .form-control,
.search-form
.search-field{
    border-radius: 0;
    border: 0 none;
}

.bg-brand-primary{
   background: $brand-primary;
}


.flexslider{
    background: transparent;
    border: 0 none;
    border-radius: 0;
    margin: 0;
    width: 100%;
    .slides {
        float: left;
        img{
            max-width: 100%;
            width: auto;
        }
    }
    .flex-viewport{
        float: left;
        width: 100%;
    }
    .flex-control-paging{
        display: none;
    }
}



.styled-select {
    border: 1px solid $mid-grey;
    display: inline-block;
    overflow: hidden;
    background: #fff;
    border: 1px solid #ccc;
    position: relative;
    width: 200px;
    &::after{
        content: "\f107";
        display: block;
        font-family: 'fontawesome';
        color: $brand-primary;
        font-size: 21px;
        position: absolute;
        right: 10px;
        top:1px;
        pointer-events: none;
    }
     select{
          background: transparent;
           width: 228px;
           padding: 5px;
           font-size: 16px;
           line-height: 1;
           border: 0;
           border-radius: 0;
           height: 34px;
           -webkit-appearance: none;
           appearance: none;
     }
   }


#twitterBox{
    font-size: 12px;
    max-width: 100%;
    word-break: break-all;

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1){
        margin-left: - $grid-gutter-width / 2;
    }
    .twitter-header{

        color: $mid-grey;
        span{
            color: $dark-grey;
            font-size: 18px;
            padding-right: 2px;
        }
    }
    .twitter-footer{
        padding: 5px 0;
        font-size: 12px;
        a:first-child{
            float: left;
        }
        a, a:last-child{
            float: right;
        }
    }
    a{
        color: $link-blue;
        font-size: 12px;
        transition: .25s all ease;
    }

}
#twitterFetcher{
    height: 260px;
    overflow: auto;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    padding: 5px 0;
     > ul {
        padding: 0;
        list-style: none;
        >li{
            border-bottom: 1px solid $light-grey;
            margin-bottom: 10px;
            padding-right: 10px;
            overflow: hidden;
            position: relative;
            &:last-child{
                border-bottom: 0 none;
            }
            .user{
                //overflow: hidden;
                padding-bottom: 4px;

                a {
                  color: $light-blue;
                  font-size: 12px;
                  span:nth-child(2){
                      color: $dark-grey;
                      font-weight: 700;
                  }
                  span {
                    float: left;
                    padding: 0 3px 0;

                    [title*='Verified']{
                        border-radius: 50%;

                        &:after{
                            background: #fff;
                            color: $twitter-blue;
                            font-family: 'FontAwesome';
                            content: '\f058';
                            position: absolute;
                        }
                    }
                    &:last-child{
                            display: block;
                            float: none;
                            text-indent: 3px;
                        }
                  }
                }
                img{
                    border-radius: 5px;
                    max-width: 30px;
                    height: auto;
                }
            }
            .tweet{
                font-size: 12px;
                padding-left: 40px;
                word-wrap: break-word;
                word-break: break-all;
            }
            .timePosted{
                float: right;

                text-align: right;
                a{
                   color: $light-grey;
                   &:hover,&:focus{
                       color: $mid-grey;
                   }
                }
            }
        }
     }
     .interact>a{
        display: block;
        float: left;
        text-indent: -1000px;
        width: 25px;
        overflow: hidden;
        margin-right: 5px;
        transition:.25s color ease;
         &:hover:before,&:focus:before{
             color: $mid-grey;
         }
     }
     .interact>a:before{
         color: $light-grey;
         display: block;
         font-family: 'FontAwesome';
         position: absolute;
         text-indent: 0;
     }
     .twitter_reply_icon:before{
         content: '\f112';
     }
     .twitter_retweet_icon:before{
         content: '\f079';
     }
     .twitter_fav_icon:before{
         content: '\f004';
     }
}

.custom-wrapper{
    padding-left:10px;
    padding-right:10px;
    @media (min-width:$screen-sm-min){
        padding-left:35px;
        padding-right:35px;
    }
    @media (min-width:$screen-lg-min){
        padding-left:120px;
        padding-right:120px;
    }
}

main{
    padding:25px 0 0 0 !important;
    float:none !important;
    background:white;
}

.home, .blog, .archive.category, .post-type-archive-match, .page-template-template-taxonomy_team, .archive.tax-team, .single-person, .page-parent, .page-template-template-legends-lounge {
    main{
        padding:0 !important;
        background:transparent;
    }
}

.home{
    .main{
        overflow: hidden;
    }
}

.top-banner-100{
    position:fixed;
    max-width:100%;
    height:auto;
    z-index:-1;
    width:100%;
}

.single-post{
    main{
        padding:0 !important;
    }
}

.row-eq-height{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.h-100{
    height: 100%;
}

.order-1{
    order:1;
}

.order-2{
    order:2;
}

.order-3{
    order:3;
}

.search{
    .btn-article{
        background: $newGold;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-top: 0 none;
        border-bottom: 0 none;
        font-weight: 800;
        color: black;
        margin: 0 15px;
        position: relative;
        transform:none;
        z-index: 1;
        span{
            transform:none;
        }
    }
}

.custom-404-page{
    padding:50px 0;
    .title-404{
        text-align:center;
        width:100%;
    }
    .text-404{
        text-align:center;
    }
    .page-form{
        text-align:center;
        margin-top:25px;
        form{
            input[type="search"]{
                text-align:left;
            }
        }
    }
}

.video-link--image{
  display: flex !important;
  justify-content: center;
  align-items: center;

  .fa{
    font-size: 100px;
    color: #fff;
    text-shadow: 0 0 3px rgba(0,0,0,.85);
    position: absolute;
    line-height: 75%;
    background: rgba(0,0,0,.35);
    border-radius: 50%;
  }
}

.sponsorship-text-notification{
    padding: 1em 2em 1em 3.5em;
    margin: 0 0 2em;
    position: relative;
    background-color: #f7f6f7;
    color: #515151;
    border-top: 3px solid green;
    list-style: none outside;
    width: auto;
    word-wrap: break-word;
    font-weight:700;
    .inner{
        margin:0 auto;
        text-align: center;
    }
}
