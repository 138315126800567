html {
    scroll-behavior: smooth;
}

.page-template-template-kit-history {
    main {
        background: transparent;
        padding:0 !important;
    }
    .custom-title-wrapper{
        padding:0;
    }
}

.custom-kit-history{
    color: $white;
    padding:0;

    .custom-kit-wrapper{
        display:flex;
        flex-direction: column;
        padding-top:50px;
        padding-bottom:50px;
        background-size:cover;
        background-repeat:no-repeat;
        background-attachment: fixed;
        @media(min-width:993px){
            flex-direction: row;
        }

        .title{
            @media(max-width:767px){
                display:flex;
                justify-content: space-between;
                align-items: flex-end;
                flex-wrap: wrap;
            }

            width:100%;
            @media(min-width:993px){
                width:400px;
            }

            .wrap{
                margin-right:1rem;
                margin-left:0;
                span{
                    font-family: Zooja, sans-serif;
                    color: $newLightBlue;
                    font-size:32px;
                    @media(min-width:850px) {
                        font-size:40px;
                        transform: rotate(-15deg) translate(-25px, 15px);
                    }
                    display: block;
                    transform-origin: bottom left;
                }
                h1{
                    margin:0;
                    text-transform: uppercase;
                    font-weight:700;
                }
            }
            .grid-button{
                display:block;
                margin-left:0;
                @media(min-width:768px){
                    display:none;
                }
            }
        }

        .kit-carousel-section {
            width:100%;
            @media(min-width:1350px) {
                width: calc(100% - 400px);
            }

            .kit-section{
                .middle-section{
                    display:flex;
                    align-items: center;
                    flex-direction: column;
                    @media(min-width:1351px) {
                        width: calc(100% - 250px);
                    }
                    justify-content: center;
                    @media(min-width:425px){
                        flex-direction: row;
                    }


                    .holder{
                        display:flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        @media(max-width:424px) {
                            width: 100%;
                        }
                        .toggler{
                            text-align: center;
                            h2{
                                font-weight:700;
                                transition:0.5s;
                            }
                            ul.nav-tabs{
                                text-transform:uppercase;
                                border:0;
                                border-radius:10px;
                                li{
                                    font-weight:700;
                                    a{
                                        font-size: 12px;
                                        padding:8px 4px;
                                        @media(min-width:330px) {
                                            padding: 8px 7px;
                                        }
                                        @media(min-width:475px) {
                                            font-size: 14px;
                                        }
                                        @media(min-width:768px) {
                                            font-size: 16px;
                                            padding: 12px;
                                        }

                                        border:1px solid $newLightBlue;
                                        color: $white;
                                        background:rgba($newMainBlue, 0.7);
                                        margin:0;
                                        &.away-kit-selector.third-active{
                                            border-radius:0 !important;
                                        }
                                    }
                                    &:first-child{
                                        a{
                                            border-radius: 10px 0 0 10px;
                                        }
                                    }
                                    &:last-child{
                                        a{
                                            border-radius: 0 10px 10px 0;
                                        }
                                    }
                                    &.active{
                                        a{
                                            background: $newLightBlue;
                                            color: $newMainBlue;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .navigation{
                        display:flex;
                        flex-direction: row-reverse;
                        @media(min-width:425px) {
                            flex-direction: column;
                            margin-left: 25px;
                        }

                        .kit-history-nav{
                            margin:20px 10px;
                            @media(min-width:425px) {
                                margin: 10px 0;
                            }
                            border:1px solid $newLightBlue;
                            width: 70px;
                            height: 70px;
                            font-size:14px;
                            @media(min-width:1600px) {
                                width: 100px;
                                height: 100px;
                                font-size:17px;
                            }
                            background:$newDarkBlue;
                            color:$white;
                            display:flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            border-radius:50%;
                            font-weight:700;
                            text-transform:uppercase;

                            &.disabled{
                                opacity:0.3;
                                pointer-events: none;
                            }

                            span{
                                font-size:30px;
                                color: $newGold;
                            }
                        }
                    }
                }
                .sponsor-section{
                    @media(min-width:1351px) {
                        width: 250px;
                    }
                    font-weight:700;
                    font-size:20px;
                    display:flex;
                    justify-content: center;
                    @media(min-width:768px) {
                        justify-content: space-between;
                    }
                    margin-top:50px;
                    @media(min-width:1350px) {
                        margin-top:0;
                        flex-direction: column;
                    }

                    .sponsor{
                        display:flex;
                        flex-direction: column;
                        align-items: center;
                        @media(min-width:1351px) {
                            align-items: flex-end;
                        }
                        .text{
                            font-size:14px;
                            @media(min-width: 1600px){
                                font-size:17px;
                            }
                        }
                        img{
                            margin-top:10px;
                            max-width:100%;
                            height:auto;
                        }
                    }
                }
            }
        }
    }

    #grid-section{
        background:$newDarkBlue;
        padding:50px 0;
        background-size:cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        h2{
            text-align: center;
            font-weight:700;
            margin-bottom:50px;
            margin-top:0;
        }

        .kit-grid{
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            gap:30px 10px;
            @media(min-width:425px) {
                gap: 50px 20px;
            }
            @media(min-width:576px) {
                gap: 75px 30px;
            }
            @media(min-width:993px) {
                grid-template-columns: repeat(4, 1fr);
            }
            .kit-item{
                display:flex;
                flex-direction: column;
                align-items: center;
                h4{
                    margin-top:15px;
                    font-weight:700;
                    margin-bottom:5px;
                }
                h3{
                    margin-top:0;
                }

                .kit-wrapper{
                    display:flex;
                    align-items: center;
                    position:relative;
                    padding-left:17.7%;
                    width:100%;
                    img{
                        max-width:100%;
                        position:absolute;
                        width:100%;
                        height:100%;
                        object-fit: contain;
                        transform:translate(-50%, -50%);
                        left:50%;
                        top:50%;
                        filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
                    }

                    .shirt-shorts-wrapper{
                        display:flex;
                        flex-direction: column;
                        width:80%;
                        .shirt-wrapper{
                            height: 0;
                            padding-bottom: 89%;
                            position:relative;
                            transition:0.5s;
                        }
                        .shorts-wrapper{
                            height: 0;
                            padding-bottom: 57%;
                            position:relative;
                            transition:0.5s;
                        }
                    }
                    .socks-wrapper{
                        width:20%;
                        position:relative;
                        transition:0.5s;
                        height:100%;
                        .socks{
                            width:100%;
                            position:absolute;
                            top:50%;
                            left:50%;
                            object-fit: contain;
                            transform: translate(-105%,-45%);
                        }
                    }
                }

            }
        }
    }
}


.grid-button{
    text-transform:uppercase;
    display: none;
    margin-right: 0;
    margin-left: auto;
    @media(min-width:768px){
        display:flex;
    }
    a{
        color: $white;
        display:flex;
        align-items: center;
        font-weight:700;
        span{
            margin-left:1rem;
            font-size:30px;
            color:$newLightBlue;
            transition: color 0.3s;
        }

        &:hover{
            text-decoration: none;
            span{
                color: $white;
            }
        }
    }
}

.kit-items{
    width:100%;
    .kit-output{
        display:flex;
        position:relative;
        width:auto;
        max-width:80%;
        margin-top:20px;
        padding-left:17.7%;
        @media(min-width:425px) {
            max-width:100%;
            width: 300px;
        }
        @media(min-width:576px) {
            width: 425px;
        }
        @media(min-width:1450px) {
            width: 500px;
        }
        @media(min-width:1600px) {
            width: 600px;
        }
        @media(min-width:1700px) {
            width: 700px;
        }

        .no-kit{
            position:absolute;
            width:100%;
            text-align:center;
            top:0;
            font-weight:700;
            text-transform: uppercase;
            margin-left: -17.7%;
        }

        img{
            max-width:100%;
            position:absolute;
            width:100%;
            height:100%;
            object-fit: contain;
            transform:translate(-50%, -50%);
            left:50%;
            top:50%;
            filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
        }
        .shirt-shorts-wrapper{
            display:flex;
            flex-direction: column;
            width:80%;
            .shirt-wrapper{
                height: 0;
                padding-bottom: 89%;
                position:relative;
                transition:0.5s;
            }
            .shorts-wrapper{
                height: 0;
                padding-bottom: 57%;
                position:relative;
                transition:0.5s;
            }
        }
        .socks-wrapper{
            width:20%;
            position:relative;
            transition:0.5s;
            .socks{
                width:100%;
                position:absolute;
                top:50%;
                left:50%;
                object-fit: contain;
                transform:translate(-50%, -50%);
            }
        }
    }
}

.kit-section{
    display: none;
    justify-content: space-between;


    &.active {
        display: flex;
        flex-direction: column;
        @media(min-width:1350px){
            flex-direction: row;
        }
    }
    &.removing, &.adding{
        .kit-output{
            .shirt-wrapper{
                transform:translateY(-100px);
                opacity:0;
            }
            .shorts-wrapper{
                transform:translateY(100px);
                opacity:0;
            }
            .socks-wrapper{
                opacity:0;
            }
        }
    }

    &.removing{
        .toggler{
            h2{
                transform:translateX(-100px);
                opacity:0;
            }
        }
    }

    &.adding{
        .toggler{
            h2{
                transform:translateX(100px);
                opacity:0;
            }
        }
    }
}

.vote-block{
    width:400px;
    border-radius:10px;
    border:1px solid $newLightBlue;
    background:rgba($newMainBlue, 0.7);
    padding:20px;
    margin:30px 0;

    &.desktop{
        display:none;
        @media(min-width:993px){
            display:block;
        }
    }

    &.mobile{
        margin-top:0;
        display:block;
        @media(min-width:993px){
            display:none;
        }
        margin-bottom:0;
        border:0;
        width:100%;
        border-radius:0;
        padding:50px 20px;
        .description{
            margin-top:25px;
        }
    }

    h3{
        margin:0;
        font-weight:700;
    }

    form{
        width:100%;
        .form-wrapper{
            background:white;
            display:flex;
            justify-content: space-between;
            border-radius:10px;
            padding:5px;
            margin:1rem 0;
            select{
                border:0;
                box-shadow: none;
                color: $newMainBlue;
                option{
                    text-transform: uppercase;
                }
            }
            button[type="submit"]{
                background:$newLightBlue;
                color: $newMainBlue;
                text-transform:uppercase;
                border-radius:10px;
                border:0;
                box-shadow: none;
                font-weight:700;
                padding:10px 15px;
            }
        }
    }

    .description{
        font-size:14px;
        a{
            color: $white;
            text-decoration: underline;
            text-decoration-color: $newLightBlue;
            font-weight:700;
            &:hover{
                text-decoration: none;
            }
        }
    }
}


