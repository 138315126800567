.custom-single-page{
    &.legends-lounge{



        .banner{
            height: calc(100% + 110px);
            width:100%;
            position:absolute;
            top:0;
            left:0;
            margin-top: -110px;
            background-attachment: fixed;
            overflow: hidden;
        }

        h1{
            text-transform:uppercase;
            color:white;
        }



        .custom-wrapper{
            &.first-wrapper{
                position: relative;
            }

            .btn-primary{
                background: $newGold;
                border: 0px solid #D0D0D0;
                color: #172743;

                &:hover{
                    background: #D0D0D0;
                    border: 0px solid #D0D0D0;
                }
            }

            .row{
                .page-article{
                    h1 {
                        text-align: center;

                        @media(max-width: $screen-lg-min){
                            text-align: left;
                        }
                    }

                    .article-header{
                        img{
                            height:auto;
                            min-height:350px;
                            display:block;
                            margin:0 auto;
                            @media(max-width:768px){
                                min-height:0;
                                width:auto;
                            }
                        }
                    }
                    .entry-content{
                        background:white;
                        padding:25px 50px;
                    }

                    .product-row{
                        margin: -60px 0 16px;
                        display: flex;
                        justify-content: space-evenly;
                        flex-wrap: wrap;

                        .product-item{
                            background: white;
                            padding: 1rem 1.6rem;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;

                            @media(max-width:767px){
                                width:100%;
                                margin-bottom: 3rem;

                            }

                            p{
                                color: $newDarkBlue;
                                text-transform: uppercase;
                            }

                            .title{
                                font-weight: 700;
                                font-size: 18px;
                                margin: 0;
                                flex-grow: 1;
                            }

                            .price{
                                font-weight: 700;
                                font-size: 24px;
                            }

                            .sale-price-output{
                                display:flex;
                                font-weight: 700;
                                font-size: 24px;
                                gap:1rem;
                                margin-bottom:10px;
                                .regular-price{
                                    position:relative;
                                    opacity:0.8;
                                    &:after{
                                        content:"";
                                        position:absolute;
                                        height:2px;
                                        width:100%;
                                        background: red;
                                        top:50%;
                                        left:50%;
                                        transform: translate(-50%, -50%);
                                    }
                                }
                            }
                            
                            input[type="number"] {
                                width: 100%;
                                margin-bottom: 15px;
                            }

                            button{
                                text-transform: uppercase;
                                font-weight: 700;
                                font-size: 1.5rem;
                                padding: 10px 36px;
                                margin-bottom: -25px;
                                width: 100%;
                            }

                            &.simple-product {
                                margin-top: 100px;
                            }
                        }
                    }

                    .under-product-row{
                        margin: 5rem 0 0;
                        padding-bottom: 2rem;

                        .under-product-content{
                            p{
                                color: white;
                                text-align: center;
                            }
                            a{
                                color:white;
                                text-decoration:underline;
                                &:hover{
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    .modal{

                        .modal-dialog{

                            @media (min-width: 850px){
                                width: 845px;
                            }

                            @media (min-width: 1200px){
                                width: 1100px;
                            }

                            .modal-content{
                                background-color: #202020;
                                background-size: cover;

                                .modal-header{
                                    padding: 0.5rem 1rem 0;
                                    border-bottom: unset;

                                    .close{
                                        color: white;
                                        opacity: 1;
                                        font-size: 32px;
                                    }
                                }

                                .modal-body{
                                    padding: 15px 35px 35px;
                                    height: fit-content;
                                    overflow: auto;

                                    .modal-content-container{
                                        background: rgba(0, 12, 45, 0.73);
                                        padding: 3.5rem;
                                        height: fit-content;
                                        overflow: auto;

                                        .variation-top{
                                            color: white;
                                            max-width: 680px;
                                            margin: 0 auto 1.2rem;

                                            @media(max-width:575px){
                                                display:flex;
                                                align-items: center;
                                                flex-direction: column;
                                            }

                                            .heading{
                                                display: flex;
                                                flex-direction: column;
                                                align-items: center;

                                                .title{
                                                    font-size: 32px;
                                                    font-weight: 500;
                                                }

                                                .date{
                                                    font-size: 22px;
                                                    font-weight: 500;
                                                }
                                            }

                                            .vs{
                                                @media(max-width:575px) {
                                                    padding-top: 0;
                                                }
                                                p{
                                                    font-size:24px;
                                                    @media(min-width:650px) {
                                                        font-size: 38px;
                                                    }
                                                }
                                            }

                                            .home-team, .opposing-team{
                                                display: flex;
                                                justify-content: center;
                                                flex-direction: column;
                                                align-items: center;

                                                .crest-wrapper{
                                                    width:140px;
                                                    @media(min-width:650px) {
                                                        width: 200px;
                                                    }
                                                    .image-wrapper{
                                                        height:0;
                                                        padding-bottom:100%;
                                                        position:relative;
                                                        img{
                                                            position:absolute;
                                                            max-width:80%;
                                                            max-height:80%;
                                                            top:50%;
                                                            left:50%;
                                                            transform: translate(-50%, -50%);
                                                        }
                                                    }
                                                }

                                                img{
                                                    max-width:100%;
                                                }

                                                .team-name{
                                                    margin: 12px 0;
                                                    font-size:20px;
                                                    @media(min-width:650px) {
                                                        font-size: 30px;
                                                    }
                                                    font-weight: 600;
                                                }
                                            }
                                        }

                                        .variation-top-info{
                                            background: white;
                                            display: flex;
                                            align-items: center;
                                            color: $newMainBlue;
                                            padding: 1rem 0;

                                            @media(max-width:768px){
                                                max-width:100%;
                                                flex-direction: column;
                                            }

                                            .availability, .price, .price-wrapper, .quantity, .buy-button{
                                                display: flex;
                                                flex-direction: column;
                                                align-items: center;
                                                justify-content: center;

                                                .title{
                                                    font-weight: 600;
                                                }

                                                .information{
                                                    font-size: 22px;
                                                    font-weight: 700;
                                                    margin: 0;
                                                }

                                                .quantity-input{
                                                    font-size: 18px;
                                                    font-weight: 700;
                                                    width: 100%;
                                                    max-width: 62px;
                                                    border: 2px solid $newMainBlue;
                                                    border-radius: 0.5rem;
                                                }
                                            }
                                        }

                                        .buy-button{
                                            @media(max-width:768px){
                                                margin-top:1rem;
                                            }
                                        }

                                        .upcoming-matches{

                                            .upcoming-title{
                                                text-align: center;
                                                font-size: 26px;
                                                font-weight: 700;
                                                margin-top: 1.5rem;
                                            }

                                            .match-col-row{
                                                margin-top: 0.5rem;

                                                .match-row{
                                                    background: rgba(255, 255, 255, 0.25);
                                                    display: flex;
                                                    align-items: center;
                                                    color: white;
                                                    padding: 1rem 0;
                                                    transition: .3s ease;

                                                    @media(max-width: 768px){
                                                        flex-direction: column;
                                                    }

                                                    &:hover{
                                                        background: white;
                                                        color: $newMainBlue;
                                                    }

                                                    .match, .date,.availability, .price-wrapper, .price, .quantity, .buy-button{
                                                        display: flex;
                                                        flex-direction: column;
                                                        align-items: center;
                                                        justify-content: center;

                                                        .title{
                                                            font-weight: 600;
                                                        }

                                                        .information{
                                                            font-size: 20px;
                                                            font-weight: 700;
                                                            margin: 0;
                                                        }

                                                        .quantity-input{
                                                            font-size: 18px;
                                                            font-weight: 700;
                                                            width: 100%;
                                                            max-width: 62px;
                                                            border: 2px solid $newMainBlue;
                                                            border-radius: 0.5rem;
                                                            color: $newMainBlue;
                                                        }
                                                    }

                                                    .price-wrapper{
                                                        .information{
                                                            text-align: center;
                                                        }
                                                    }

                                                    .availability, .price-wrapper, .quantity, .buy-button{
                                                        @media (min-width: 850px){
                                                            width: 12.3333333333%;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#custom-cart-popup{
    background: #000223;
    color: white;
    position:fixed;
    bottom:5rem;
    left:5rem;
    z-index: 10000;

    @media(max-width: 430px) {
        left: 50%;
        transform: translateX(-50%);
        bottom:7rem;
    }

    display:none;
    border: 4px solid #3DBFEF;
    padding:2rem;
    width:280px;
    max-width:100%;
    button{
        position:absolute;
        top:0;
        right:0;
        border:0;
        border-radius:50%;
        width:30px;
        height:30px;
        padding:0;
        display:flex;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        background: #DCB466;
        color: #000223;
        transform:translate(50%, -50%);
        font-weight:700;
    }
    h3{
        margin-top:0;
    }
    a{
        color: #DCB466;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
}
