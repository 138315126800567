.developments-header {
    padding-top: 25px;
    padding-bottom: 25px;
    color: $white;

    .custom-wrapper {
        display: flex;
        align-items: flex-end;
        column-gap: 32px;
    }

    &__title {
        flex-grow: 1;

        h1 {
            font-size: 42px;
            font-weight: 700;
            line-height: 60px;
            margin-top: 0;
            text-transform: uppercase;
        }
    }

    &__content {
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .custom-dropdown {
        position: relative;
        width: auto;
        top: auto;
    }
}

.developments-body {
    padding-bottom: 100px;

    h1 {
        font-weight: 700;
        margin-bottom: 24px;
    }

    &__nav {
        margin-top: 50px;

        @media(min-width: $screen-md-min) {
            margin-top: 0;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                a {
                    display: block;
                    padding: 35px;
                    font-size: 18px;
                    background-color: #1B2740;
                    color: $white;
                    border-bottom: 1px solid #0B1013;
                }

                &:last-child {
                    a {
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    &__content-wrapper {
        background: rgba(255, 255, 255, 0.74);
        padding: 25px;
        margin-left: -10px;
        margin-right: -10px;

        @media(min-width: $screen-sm-min) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__cta {
        background: $newGold;
        color: #000;
        display: inline-block;
        text-transform: uppercase;
        width: fit-content;
        padding: 10px 20px;
        font-size: 18px;
        font-weight: 800;
        text-align: center;
        transition: background 0.3s ease;

        &:hover {
            background: darken($newGold, 10%);
            text-decoration: none;
        }
    }

    &__cta-wrapper {
        margin-top: 15px;
    }

    &__featured-video,
    &__featured-image {
        &__wrapper {
            position: relative;
            padding-top: 56.25%;

            iframe,
            object,
            embed,
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__featured-video {
        &__wrapper {
            margin-top: 25px;
        }
    }

    &__featured-image {
        &__wrapper {
            margin-bottom: 20px;
        }
    }

    &__gallery {
        &-slider {
            overflow: hidden;
            margin-bottom: 20px;
        }

        &-slide {
            position: relative;
            padding-top: 56.25%;

            a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-nav {
            overflow: hidden;

            &__wrapper {
                display: grid;
                grid-template-columns: auto 1fr auto;
                align-items: center;
                column-gap: 20px;
                margin-bottom: 20px;
            }

            .swiper-container {
                flex-grow: 1;
            }

            &-slide {
                position: relative;
                aspect-ratio: 16 / 9;

                &-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    box-shadow: inset 0 0 0 0 $newLightBlue;
                    z-index: 2;
                    transition: box-shadow 0.3s ease;
                    pointer-events: none;
                }

                &.swiper-slide-thumb-active {
                    .developments-body__gallery-nav-slide-overlay {
                        box-shadow: inset 0 0 0 4px $newLightBlue;
                    }
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 1;
                }
            }

            &__prev,
            &__next {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-grow: 0;
                flex-shrink: 0;
                background-color: $newGold;
                color: #172743;
                width: 24px;
                height: 24px;
                font-size: 12px;
                transition: background 0.3s ease;

                &:hover {
                    background: darken($newGold, 10%);
                    text-decoration: none;
                }

                &.swiper-button-disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }
    }
}

.developments-gallery {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #fff;

    &__body {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 20px;
        row-gap: 20px;

        @media (max-width: $screen-lg-min) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: $screen-md-min) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: $screen-xxs-min) {
            grid-template-columns: 1fr;
        }
    }

    &__item {
        &__link-wrapper {
            position: relative;
            padding-top: 56.25%;
            overflow: hidden;
        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-overlay {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            color: #fff;
            background-color: rgba(#000323, 0.72);
            font-size: 50px;
            z-index: 2;
        }

        figcaption {
            font-size: 10.5px;
            margin-top: 5px;
        }
    }

    ul.nav-tabs {
        margin-bottom: 30px;
        border: none;

        >li {
            margin-bottom: 6px;

            >a {
                position: relative;
                color: #fff;
                border-radius: 0;
                border: none;

                &::before,
                &::after {
                    position: absolute;
                    content: '';
                    left: 0;
                    bottom: 0;
                    height: 1px;
                    width: 100%;
                    background-color: $newLightBlue;
                    opacity: 0;
                    transition: opacity 0.3s ease, bottom 0.3s ease;
                }

                &:hover {
                    background-color: transparent;
                    border: none;

                    &::before {
                        opacity: 1;
                    }
                }
            }

            &.active {
                >a {
                    background-color: transparent;
                    border: none;

                    &::before,
                    &::after {
                        opacity: 1;
                    }

                    &::after {
                        bottom: -5px;
                    }

                    &:hover {
                        color: #fff;
                        background-color: transparent;
                        border: none;
                    }
                }
            }
        }
    }
}

body.page-template-template-developments-page main.main,
body.page-template-template-developments-gallery main.main>.developments-body {
    background-image: url('../images/new-assets/developments-bg.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-position: center bottom;
}

body.page-template-template-developments-gallery main.main {
    padding-top: 0 !important;
    background: none;

    .developments-body {
        padding-top: 25px;
    }
}

.pswp__video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.pswp__video-wrapper iframe {
    max-width: 100%;
    max-height: 100%;
}
